import { React, useEffect, useState, useMemo } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getColors } from "../features/color/colorSlice";
import { Select } from "antd";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { createProducts, resetState } from "../features/product/productSlice";

let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  brand: yup.string().required("Brand is Required"),
  category: yup.string().required("Category is Required"),
  tags: yup.string().required("Tag is Required"),
  color: yup
    .array()
    .min(1, "Pick at least one color")
    .required("Color is Required"),
  quantity: yup.number().required("Quantity is Required"),
  diskSizes: yup
    .array()
    .of(
      yup.object().shape({
        size: yup.string().required("Size is Required"),
      })
    )
    .min(1, "At least one disk size is required"),
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(getColors());
  }, [dispatch]);

  const brandState = useSelector((state) => state.brand.brands);
  const catState = useSelector((state) => state.pCategory.pCategories);
  const colorState = useSelector((state) => state.color.colors);
  const imgState = useSelector((state) => state.upload.images);
  const newProduct = useSelector((state) => state.product);
  const { isSuccess, isError, isLoading, createdProduct } = newProduct;

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfully!");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading, createdProduct]);

  const colorOptions = useMemo(
    () =>
      colorState.map((i) => ({
        label: i.title,
        value: i._id,
      })),
    [colorState]
  );

  const imagesList = useMemo(
    () =>
      imgState.map((i) => ({
        public_id: i.public_id,
        url: i.url,
      })),
    [imgState]
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
      brand: "",
      category: "",
      tags: "",
      color: [],
      quantity: "",
      images: [],
      diskSizes: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(createProducts(values));
      formik.resetForm();
      setColor([]);
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  useEffect(() => {
    formik.setFieldValue("color", color);
    formik.setFieldValue("images", imagesList);
  }, [color, imagesList]);

  const handleColors = (e) => {
    setColor(e);
  };

  // Disk size handlers
  const addDiskSize = () => {
    formik.setFieldValue("diskSizes", [
      ...formik.values.diskSizes,
      { size: ""},
    ]);
  };

  const removeDiskSize = (index) => {
    const diskSizes = [...formik.values.diskSizes];
    diskSizes.splice(index, 1);
    formik.setFieldValue("diskSizes", diskSizes);
  };

  const handleDiskSizeChange = (index, event) => {
    const { name, value } = event.target;
    const diskSizes = [...formik.values.diskSizes];
    diskSizes[index][name] = value;
    formik.setFieldValue("diskSizes", diskSizes);
  };

  return (
    <div>
      <h3 className="mb-4 title">Add Product</h3>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-3 flex-column"
        >
          <CustomInput
            type="text"
            label="Enter Product Title"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
          <div className="">
            <ReactQuill
              theme="snow"
              name="description"
              onChange={formik.handleChange("description")}
              value={formik.values.description}
            />
          </div>
          <div className="error">
            {formik.touched.description && formik.errors.description}
          </div>
          <CustomInput
            type="number"
            label="Enter Product Price"
            name="price"
            onChng={formik.handleChange("price")}
            onBlr={formik.handleBlur("price")}
            val={formik.values.price}
          />
          <div className="error">
            {formik.touched.price && formik.errors.price}
          </div>
          <select
            name="brand"
            onChange={formik.handleChange("brand")}
            onBlur={formik.handleBlur("brand")}
            value={formik.values.brand}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Brand</option>
            {brandState.map((i, j) => (
              <option key={j} value={i.title}>
                {i.title}
              </option>
            ))}
          </select>
          <div className="error">
            {formik.touched.brand && formik.errors.brand}
          </div>
          <select
            name="category"
            onChange={formik.handleChange("category")}
            onBlur={formik.handleBlur("category")}
            value={formik.values.category}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Category</option>
            {catState.map((i, j) => (
              <option key={j} value={i.title}>
                {i.title}
              </option>
            ))}
          </select>
          <div className="error">
            {formik.touched.category && formik.errors.category}
          </div>
          <select
            name="tags"
            onChange={formik.handleChange("tags")}
            onBlur={formik.handleBlur("tags")}
            value={formik.values.tags}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="" disabled>
              Select Tag
            </option>
            <option value="featured">Featured</option>
            <option value="popular">Popular</option>
            <option value="special">Special</option>
          </select>
          <div className="error">
            {formik.touched.tags && formik.errors.tags}
          </div>

          <Select
            mode="multiple"
            allowClear
            className="w-100"
            placeholder="Select colors"
            value={color}
            onChange={handleColors}
            options={colorOptions}
          />
          <div className="error">
            {formik.touched.color && formik.errors.color}
          </div>

          {/* Disk Sizes Section */}
          <div>
            <label className="mb-2">Disk Sizes:</label>
            {formik.values.diskSizes.map((diskSize, index) => (
              <div key={index} className="disk-size-inputs mb-3">
                <input
                  type="text"
                  name="size"
                  placeholder="Size"
                  value={diskSize.size}
                  onChange={(e) => handleDiskSizeChange(index, e)}
                  onBlur={formik.handleBlur(`diskSizes[${index}].size`)}
                  className="form-control mb-1"
                />
                <div className="error">
                  {formik.touched.diskSizes &&
                    formik.touched.diskSizes[index] &&
                    formik.touched.diskSizes[index].size &&
                    formik.errors.diskSizes &&
                    formik.errors.diskSizes[index] &&
                    formik.errors.diskSizes[index].size}
                </div>
                {/* <input
                  type="number"
                  name="price"
                  placeholder="Price"
                  value={diskSize.price}
                  onChange={(e) => handleDiskSizeChange(index, e)}
                  onBlur={formik.handleBlur(`diskSizes[${index}].price`)}
                  className="form-control mb-1"
                />
                <div className="error">
                  {formik.touched.diskSizes &&
                    formik.touched.diskSizes[index] &&
                    formik.touched.diskSizes[index].price &&
                    formik.errors.diskSizes &&
                    formik.errors.diskSizes[index] &&
                    formik.errors.diskSizes[index].price}
                </div> */}
                <button
                  type="button"
                  onClick={() => removeDiskSize(index)}
                  className="btn btn-danger"
                >
                  Remove
                </button>
              </div>
            ))}
            <div className="error">
              {formik.touched.diskSizes &&
                typeof formik.errors.diskSizes === "string" &&
                formik.errors.diskSizes}
            </div>
            <button
              type="button"
              onClick={addDiskSize}
              className="btn btn-primary"
            >
              Add Disk Size
            </button>
          </div>

          <CustomInput
            type="number"
            label="Enter Product Quantity"
            name="quantity"
            onChng={formik.handleChange("quantity")}
            onBlr={formik.handleBlur("quantity")}
            val={formik.values.quantity}
          />
          <div className="error">
            {formik.touched.quantity && formik.errors.quantity}
          </div>
          <div className="bg-white border-1 p-5 text-center">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="showimages d-flex flex-wrap gap-3">
            {imgState?.map((i, j) => (
              <div className="position-relative" key={j}>
                <button
                  type="button"
                  onClick={() => dispatch(delImg(i.public_id))}
                  className="btn-close position-absolute"
                  style={{ top: "10px", right: "10px" }}
                ></button>
                <img src={i.url} alt="" width={200} height={200} />
              </div>
            ))}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct;
